.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 90vh;
    padding: 5vh 5%;
    background: lightpink;
}

.section{
    padding-top: 2rem;
    width: 80%;
    display: flex;
    flex-direction: row;
}

.sectionLeft{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 50%;
    padding: 0.5rem 1rem;
}

.column{
    display: flex;
    flex-direction: column;
}

.sectionRight{
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.firstLine{
    display: flex;
    flex-direction: column;
    border-left: navy 30px solid;
    border-radius: 0;
    height: 100%;
}

.secondLine{
    display: flex;
    flex-direction: column;
    border-right: navy 30px solid;
    border-radius: 0;
    height: 100%;
}

.nucleotide{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    border-radius: 0.5rem;
    width: 1.5rem;
    height: 1.5rem;
    padding: 1.5rem;
    font-size: 30px;
    color: white;
    margin-top: 0.3rem;
    margin-bottom: 0.3rem;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    font-weight: 600;
    cursor: pointer;
}

@media (max-width: 676px){
    .section{
        padding-top: 5vh;
        width: 90%;
        height: 75%;
    }

    .firstLine{
        height: 100%;
        border-left: navy 10px solid;
    }

    .secondLine{
        height: 100%;
        border-right: navy 10px solid;
    }

    .nucleotide{
        width: 1rem;
        height: 1rem;
        padding: 1rem;
    }
}