.container{
    background: lightcoral;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 90vh;
    padding: 5vh 5%;
}

.section{
    padding-top: 2rem;
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

@media (max-width: 676px){
    .section{
        width: 90%;
    }
}