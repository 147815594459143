.container{
    width: 20rem;
    height: 23rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.back{
    width: 15rem;
    height: 19rem;
    border-radius: 1rem;
    background-image: url("../../assets/back.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.front{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15rem;
    height: 19rem;
    border-radius: 1rem;
    background: white;
}

.image{
    width: 10rem;
}

@media (max-width: 1367px){
    .container{
        width: 12rem;
        height: 14.5rem;
        padding-bottom: 1rem;
    }

    .back{
        width: 11rem;
        height: 14rem;
    }
    .front{
        width: 11rem;
        height: 14rem;
    }
    .image{
        width: 7rem;
    }
}

@media (max-width: 676px){
    .container{
        width: 8rem;
        height: 10rem;
        padding-bottom: 1rem;
    }

    .back{
        width: 8rem;
        height: 10rem;
    }
    .front{
        width: 8rem;
        height: 10rem;
    }
    .image{
        width: 5rem;
    }
}