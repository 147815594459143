.btn {
    height: 3.5rem;
    max-width: 6rem;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    line-height: 42px;
    padding-left: 5px;
    padding-right: 5px;
    border: 2px;
    font-weight: 500;
    transition: all 0.3s ease 0s;
    cursor: pointer;
    text-transform: uppercase;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    border-radius: 5px;
}

/*Da modificare - BOTTONI CON NUMERI*/

/*aux buttons: play, pause, stop*/
.btn--primary {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    height: 3.5rem;
    text-decoration: none;
    transition: transform .5s;
    background: white;
    color: #1a1818;
    padding: 0.5rem 1rem;
}

.btn--primary:hover{
    background: #e1e1e1;
    transform: scale(1.2);

}

.btn--secondary {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    height: 3rem;
    text-decoration: none;
    transition: transform .5s;
    background: #332f2f;
    color: white;
    padding: 0.5rem 1rem;
}

.btn--secondary:hover{
    transform: scale(1.2);

}

.btn--secondary--active {
    border-color: darkred;
    box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.42);
}