.container{
    display: flex;
    flex-direction: row;
    height: 100vh;
    width: 100vw;
}

.section{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 50vw;
}

@media (max-width: 740px){
    .container{
        display: flex;
        flex-direction: column;
    }
    .section{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 50vh;
        width: 100vw;
    }
}